import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "Diffusivity", content: "High" },
  {
    color: "bg-case_orange",
    title: "Lottery Method",
    content: "Instant drawing",
  },
  { color: "bg-accent", title: "Result Confirmation Method", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "Want my tweets to spread." },
  { title: "Send coupon codes to eliminate the hassle of sending paper media" },
  { title: "Want to distribute our own coupon codes and promote sales." },
  {
    title:
      "Want to increase the number of visits to my physical store or e-commerce site.",
  },
]

const InstantWinCouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Twitter Campaign Case Study / Coupon Distribution Campaign"
          pagepath={location.pathname}
          pagedesc="This campaign aims to promote store visits and purchases by distributing coupons to participants."
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                Coupon Distribution&nbsp;
                <br className="block md:hidden" />
                Campaign
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                This campaign aims to promote store visits and purchases by
                distributing coupons to participants.
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="Coupon Distribution Campaign"
                  src="../../../../images/case_study/mv_instant-win-coupon-campaign.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                Recommended for
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Campaign Features
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      Participant
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Easiness
                      </p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        Motivation
                      </p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      Contact person
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Easiness
                      </p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  This campaign aims to promote store visits and purchases by
                  distributing coupons to participants.
                  <br />
                  <br />
                  Participation requirements are follow &amp; retweet. It is
                  easy to participate and spread the word, and the instant-win
                  format can motivate participants.
                  <br />
                  <br />
                  If your account has a physical store or e-commerce site, we
                  recommend this campaign regardless of how many followers you
                  have.
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                How would implementing&nbsp;
                <br />
                PARKLoT's campaign tools&nbsp;
                <br />
                change things?
              </h2>
              <p className="mb-6 text-text">
                PARKLoT can streamline the "flow of determining and notifying
                winners".
                <br />
                In addition, data can be accumulated that will be useful in
                planning future campaigns.
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    If the campaign is conducted manually
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Open the corresponding campaign page from Twitter
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      List of participants
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Check user details screen
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Determination of winners
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners.
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        Search Winners' Accounts
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        Creating, confirming, and sending DMs
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners.
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-2xl text-accent mb-3">
                      3-5 hours required
                      <br />
                      <span className="font-bold text-text text-xl">
                        just to complete the campaign
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Need to log in to Twitter and&nbsp;
                        <span className="font-bold">
                          check each participant's profile one by one
                        </span>
                      </li>
                      <li>
                        Need to&nbsp;
                        <span className="font-bold">
                          send DMs to winners one by one
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      When using PARKLoT
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Provide campaign information to PARKLoT and register
                        data at our company
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Campaign automatically starts, and users who retweet are
                        automatically drawn.
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        When a winner is selected, a DM with the coupon URL is
                        automatically sent.
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        The campaign implementation process takes
                        <br />
                        <span className="text-2xl text-accent leading-normal">
                          20-30 minutes!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          <span className="font-bold">
                            Sort, filter, and view the list of participants in
                            CSV format
                          </span>
                        </li>
                        <li>
                          The lottery process for winning and losing&nbsp;
                          <span className="font-bold">
                            can be fully automated
                          </span>
                        </li>
                        <li>
                          <span className="font-bold">
                            Reduce coupon delivery time and printing costs
                          </span>
                        </li>
                        <li>
                          (As a secondary effect)
                          <span className="font-bold">
                            Analyze applicant trends and use them in future
                            campaigns
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      The tool is not recommended&nbsp;
                      <br />
                      in the following cases
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>No applicant information required</li>
                        <li>Fewer winners</li>
                        <li>No cost to implement the tool</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Specific campaign procedures
                <br />
                when using PARKLoT
              </h2>
              <FlowNumber
                num="01"
                parklot={false}
                title="Please provide PARKLoT with your campaign information"
              >
                <p className="text-text">
                  Please contact us via email or Slack with your campaign
                  information at least 3 business days prior to the start of the
                  campaign.
                  <br />
                  The required data can be found on the&nbsp;
                  <a
                    className="text-accent underline"
                    href="https://www.notion.so/xhackttl/Ver-2-64d0b7b19a0548d09ec5323d1f9ce155"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [Coupon Distribution Campaign] Schedule and Submission Data
                    Ver. 2.
                  </a>
                </p>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={true}
                title="Register for the campaign"
              >
                <p className="text-text">
                  We will set up the data based on the information you provide.
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="The campaign tweet will be automatically posted and the campaign will begin"
              >
                <p className="text-text">
                  At the start time, the tweet will be automatically posted and
                  the campaign will begin.
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                last={true}
                title="Automatically send DMs to participants"
              >
                <p className="text-text">
                  Retweet users will be collected and the probability of winning
                  will be calculated to determine the winner automatically. The
                  URL where the coupon can be displayed will be automatically
                  sent to the winners via DM.
                  <br />
                  The list of campaign participants can be viewed from the
                  administration page.
                </p>
              </FlowNumber>
            </div>
          </section>
          {/* クーポンの種類は？ */}
          <section className="coupon bg-zinc-50">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                What kind of coupons are available?
              </h2>
              <p className="text-text md:mb-10 mb-5">
                Coupons are available in three types: cancelable, timed, and
                unlimited for a period of time.
              </p>
              <div className="p-4 text-center bg-white">
                <StaticImage
                  layout="constrained"
                  alt="Instant Win Campaign Implementation Costs"
                  src="../../../../images/case_study/coupon-type.png"
                  objectfit="contain"
                />
              </div>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                What features are included
                <br />
                in PARKLoT's monthly fee?
              </h2>
              <p className="text-text md:mb-10 mb-5">
                The coupon distribution campaign is available within the monthly
                fee.
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                Features included in the monthly fee
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="Automatic drawing of winners"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    Automatic drawing&nbsp;
                    <br />
                    of winners
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt=" 参加者情報のCSV出力"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    CSV output of&nbsp;
                    <br />
                    participant information
                  </h4>
                  <p className="text-text text-xs">
                    *If complex customization is required,&nbsp;
                    <br />
                    an additional fee may be charged.
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="Send automatic DM to participants with the URL of the result of the lottery"
                    src="../../../../images/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    Send automatic DM to participants&nbsp;
                    <br />
                    with the URL or coupon URL&nbsp;
                    <br />
                    of the result of the lottery
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default InstantWinCouponCampaign
